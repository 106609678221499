/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import NewHeader from "./NewHeader"
import Newsletter from "./newsletter"
import Footer from "./footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
    {/* <div className="bg-blue-400 py-3 text-lg font-light text-white">
      <div className="max-w-screen-xl mx-auto text-center">
        <span className="block mb-2 md:mb-0 md:inline md:mr-2">The Production Ready GraphQL Book is Finally Here!</span><a href="https://book.productionreadygraphql.com" className="font-semibold text-white underline">Check it Out →</a>
      </div>
    </div> */}
      <NewHeader siteTitle={data.site.siteMetadata.title} />
      <div>
        <main className="max-w-screen-xl mx-auto px-8 xl:px-0">{children}</main>
        <footer>
         <Newsletter />
         <Footer />
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
