import React, { useState } from 'react';
import { Link } from "gatsby"
import Transition from "./transition"

function NewHeader() {
  const [showMenu, setShowMenu] = useState(false);
  
  return (
    <div className="relative bg-white">
      <div className="max-w-screen-xl mx-auto">
        <div className="relative z-10 bg-white">
          <svg className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none">
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <div className="relative py-12 mt-0">
            <nav className="relative flex items-center justify-between sm:h-10 px-8 xl:px-0">
              <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <p className="hidden md:block text-xl lg:text-2xl leading-9 font-bold text-gray-800 tracking-tight leading-tight">
                  <Link to={"/"}>Production Ready GraphQL</Link>
                    <span className="ml-2 inline-flex relative">
                    <span className="absolute px-8 inset-0 bg-pink-300" style={{transform: "skewY(-.5deg) scaleX(1.05)"}}></span>
                    <span className="relative font-medium">{`{ blog }`}</span>
                  </span>
                  </p>
                  <p className="text-lg md:hidden leading-9 font-bold text-gray-800 tracking-tight leading-tight">
                    PRG
                    <span className="ml-2 inline-flex relative">
                    <span className="absolute px-8 inset-0 bg-pink-300" style={{transform: "skewY(-.5deg) scaleX(1.05)"}}></span>
                    <span className="relative font-medium">{`{ blog }`}</span>
                  </span>
                  </p>
                  <div className="-mr-2 flex items-center md:hidden">
                    <button onClick={() => setShowMenu(true)} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                      <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="hidden md:block md:ml-10 md:pr-4">
                <Link to={"/articles"} className="font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out">Articles</Link>
                <Link to={"/courses"} className="ml-8 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out">Courses</Link>
                <a href="https://twitter.com/__xuorig__" className="ml-8 font-medium italic text-blue-500 hover:text-blue-600 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out">By XuoriG</a>
              </div>
            </nav>
          </div>

          <Transition
            show={showMenu}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
              <div className="rounded-lg shadow-md">
                <div className="rounded-lg bg-white shadow-xs overflow-hidden">
                  <div className="px-5 pt-4 flex items-center justify-between">
                    <div>
                      <img className="h-8 w-auto" src="/img/logos/workflow-mark-on-white.svg" alt="" />
                    </div>
                    <div className="-mr-2">
                      <button type="button" onClick={() => setShowMenu(false)} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                        <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="px-2 pt-2 pb-3">
                    <Link to={"/articles"} className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">Articles</Link>
                    <Link to={"/courses"} className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">Courses</Link>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  )
}

export default NewHeader;